import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist1.css";
import BackofficeNavBar from "../../BackoffcieNav/BackofficeNavBar";
// import Pagination from "react-js-pagination";
// import "./pagination.css";
import AddMedicine from "./Addmedicine";
import EditMedicine from "./EditMedicine";
import Pagination from "react-js-pagination";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from 'react-alert'

   
function Medicine() {
  const alert = useAlert()
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  const [featureList1, setFeatureList1] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [action,setAction]=useState("")
  const [medicine, setMedicine] = useState([]);
  const [activePage, setActivePage] = useState(page);
  const [perPage] = useState(10);
  const [editShow, setEditShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const [display1, setDisplay1] = useState(true);
  const [display2, setDisplay2] = useState(false);
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Initialize to 1 as a default
  const [search, setSearch] = useState("name_suggest__completion");
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [visiblePages, setVisiblePages] = useState([]);
  const [pagesToShow, setPagesToShow] = useState(4);
  const [category, setCategory] = useState(2);
  const [expand, setExpand] = useState();
  const [MedConfirmModalShow,setMedConfirmModalShow]=useState(false);
  const [flag, setFlag] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
const [id,setID]=useState()
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   console.log(featureList1); // This will log the updated featureList1 when it changes
  // }, [featureList1]);
  useEffect(() => {
    // Generate visible page numbers based on the current page and total pages
    let startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const newVisiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    setVisiblePages(newVisiblePages);
  }, [page, totalPages, pagesToShow]);



  useEffect(() => {
    
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */
     

      axios
        .get(
          `${APIURL}/api/v1/doctor/medicine-search/?q=${searchTerm}&page=${page}&${TZ}`,
          {
            headers: { Authorization: "Token " + v2 },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            // setMedicine(res.data[search][0].options);
            const datas = res.data.medicines;
            console.log(datas,"datas")
            setFeatureList(datas);
            setTotalPages(res.data.total_pages);
          //  setDisplay(true);
         
          } else {
          }
        })
        .catch((error) => { });
    
  }, [searchTerm,refresh]);

  // useEffect(() => {
  //    bindMedicine(page);
  // }, [refresh]);

  const handleMedicineList =()=>{
    setDisplay1(true)
  }

  // const bindMedicine = (page) => {
    
  //   setLoading(true);
  //   const tokenString = sessionStorage.getItem("usertoken");

  //   let v2 = tokenString.replace(
  //     /["]+/g,
  //     ""
  //   ); /* REGEX To remove double quotes from tokenstring */

  //   axios
  //     .get(`${APIURL}/api/v1/service-provider/master-medicines/?page=${page}&${TZ}  `, {
  //       headers: { Authorization: "Token " + v2 },
  //     })
  //     .then((res) => {
  //       //console.log(res);
  //       if (res.data.status === "success") {
  //         setLoading(false);
  //         const datas = res.data.data;
  //         // console.log(datas);
  //         setFeatureList(datas);
  //         setTotalPages(res.data.total_pages);
  //         //console.log(featureList)
  //       } else {
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //     });
  // };

  const rentalModalClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setEditShow(false);
  };

  const modalClose = () => {
    setEditShow(false);
    setRefresh(!refresh);
  };

  const handleClose = () => {
    setRefresh(!refresh)
    setAddRentalShow(false);
    setEditShow(false);
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>
          

          {/* <Button
            variant="danger"
            onClick={() => {
              handleDelete(deleteIndex);
            }}
          >
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">medicine Deleted Successfully!!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header style={{padding:"0px",paddingRight:"2%"}} closeButton>
         <h4  className="title-of-page">Add Medicine</h4>
        </Modal.Header >
        <Modal.Body>
          <AddMedicine
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>
     
      </Modal>
    );
  };
  const handleDeleteMed=()=>{


    const tokenString= sessionStorage.getItem('usertoken');
     
     let str= tokenString.replace(/["]+/g, '');
      
  let dataToSend={}
  if(action==="in_stock"){
  dataToSend={
    in_stock:false
  }
  }else  if(action==="out_stock"){
    dataToSend={
      in_stock:true
    }
    }else if(action==="active"){
    dataToSend={
      is_active:false
    }
  }else if(action==="inactive"){
    dataToSend={
       is_active:true
    }
  }
      axios.put(`${APIURL}/api/v1/service-provider/master-medicine-detail/${id}/`, dataToSend,{ headers: {
        "Content-Type": "application/json",
        
        Authorization : 'Token '+str,
    }
  
  
      })
  
  
  
      .then(res=>{
  
         if(res.data.status === "success"){
     
      alert.success(res.data.message)
      // setMedIndexToDelete(null)
      setRefresh(!refresh)
     }
   else{
     alert.error(res.data.message)
      
     
     }
  
      })
      .catch(err=>{
    
    // setSubmitMsg("error");
  
  })
  
  
   }
  
  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditMedicine data={current} modalClose={modalClose} />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary btn-col" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleEdit = (item) => {
    // const list = featureList[index];
    setCurrent(item);
    setEditShow(true);
  };

  const handleDeletePopUp = (id) => {
    //  e.preventDefault();
    setDeleteIndex(id);
    setDeleteShow(true);
  };



  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    // bindMedicine(pageNumber)
    setRefresh(!refresh)
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setActivePage(page+1)
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setActivePage(page-1)
    }
  };
  
  const handleAddProcedure = (e,item) => {
    debugger
  
    setFeatureList1(item)
    console.log(featureList1)
    setSearchTerm("")
    setDisplay(false)
    setDisplay1(false)
    setDisplay2(true)
  }

  const handleDetail = (index) => {
    if (expand !== index) {
      setExpand(index);
    } else {
      setExpand();
    }
  };
  const handleSearchChange = (e) => {
    
    e.preventDefault();
    setSearchTerm(e.target.value);

    if (e.target.value === '') {
      setDisplay(false);
    }
    // document.body.addEventListener("click", bodyClickHandler);
  };

  // const handlePageChange = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  

 
  // const totalPages = pageNumbers.length;
  let dataDisplay = [];

  dataDisplay =
    category === 2 ? (
      featureList.length > 0 ? (
        featureList.map((item, index) => {
          return (
            <div
              className="care-features"
              key={item.id}
              style={{ cursor: "pointer" }}
              // onClick={() => handleDetail(index)}
            >

              <div className="feature-data">
                <div className="row" style={{width:"100%"}}>
                  <div className="col-9">
                  <h5 className="feature-title">
                  <b>
                    {" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDetail(index)}
                    >
                      {(page - 1) * perPage + index + 1}.<span>&nbsp;&nbsp;</span><span style={{ color: "green"}}>{item.med_code}</span><span>&nbsp;&nbsp;</span>{item.med_name}
                    </span>
                    <i
                    onClick={() =>{expand === index&& handleDetail(index)}}
                      className={
                        expand === index
                          ? "fa fa-angle-up fa-fw"
                          : "fa fa-angle-down fa-fw"
                      }
                      style={{ color: "#6788bc " }}
                    ></i>
                  </b>
                  </h5>
                  </div>
                  <div className="col-1">
                  {item.in_stock?<svg
                  style={{
                     float: "right",
                    //  marginRight : "2%",
                    color: "#5a9569",
                    cursor: "pointer",
                  }}
                  onClick={() =>{setAction("in_stock");setID(item.id);setMedConfirmModalShow(true)}}
                  xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 18h5.5v-4H6zm0-5h5.5V6H6zm6.5 5H18v-7h-5.5zm0-8H18V6h-5.5zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v2h2v2h-2v2h2v2h-2v2h2v2h-2v2q0 .825-.587 1.413T19 21zm0-2h14V5H5zM5 5v14z"/></svg> 
             :<svg  style={{
                     float: "right",
                     marginRight : "2%",
                    color: "silver",
                    cursor: "pointer",
                  }}
                  onClick={() =>{setAction("out_stock");setID(item.id);setMedConfirmModalShow(true)}}
             xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.308 17.692h5.038v-3.538H6.308zm0-4.846h5.038V6.308H6.308zm6.346 4.846h5.038v-6.538h-5.038zm0-7.846h5.038V6.308h-5.038zM5.616 20q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v2.153h1.539v1H20V11.5h1.539v1H20v2.73h1.539v1H20v2.155q0 .69-.462 1.152T18.384 20z"/></svg>
                }</div>
                  <div className="col-1">
                  {item.is_active?<svg 
                   style={{
                    float: "right",
                    // marginRight : "2%",
                   color: "green",
                   cursor: "pointer",
                 }}
                 onClick={() =>{setAction("active");setID(item.id);setMedConfirmModalShow(true)}}
                  xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.5 6.5h-9C4.5 6.5 2 9 2 12s2.5 5.5 5.5 5.5h9c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5m0 8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5S19 10.6 19 12s-1.1 2.5-2.5 2.5"/></svg>:
                  <svg 
                  style={{
                    float: "right",
                    // marginRight : "2%",
                   color: "red",
                   cursor: "pointer",
                 }}
                 onClick={() =>{setAction("inactive");setID(item.id);setMedConfirmModalShow(true)}}
                  xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M7 17q-2.083 0-3.542-1.457T2 12.005T3.458 8.46Q4.917 7 7 7h10q2.083 0 3.542 1.457T22 11.995t-1.458 3.544Q19.083 17 17 17zm0-1h10q1.65 0 2.825-1.175T21 12t-1.175-2.825T17 8H7Q5.35 8 4.175 9.175T3 12t1.175 2.825T7 16m-.002-1.5q1.04 0 1.771-.728t.731-1.77t-.728-1.771t-1.77-.731t-1.771.729t-.731 1.769t.729 1.771t1.769.731M12 12"/></svg>
               
                 } </div>
                  <div className="col-1">
                  <svg style={{
                      float: "right",
                      // paddingRight: "2%",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}onClick={() => handleEdit(item)} xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1"/><path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3"/></g></svg>
               
                  </div>
                </div>
               
                  {/* <i
                    class="fa fa-pencil"
                    onClick={() => handleEdit(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i> */}
        
                
              
              </div>

              {expand === index ? (
                <>
                  <div className="seperate-content" />
                  <div className="row-of-features">
                    <div className="col-of-features">
                    { item.image ?  <img src={item.image} alt="image" width="100" height="100" />: null}
                       <h6>Created Name : {item.created_name}</h6> 
                      <h6> ATC code: {item.med_code}</h6>
                      <h6>Composition: {item.composition}</h6>
                      <h6>Strength: {item.strength}</h6>
                      <h6>Strength Unit: {item.strength_unit}</h6>
                      <h6>Dosage Form: {item.dosage_form}</h6>
                      <h6>Route of Medicine : {item.route}</h6>
                      <h6>Description : {item.description}</h6>
                      <h6>Drug Interactions : {item.med_interactions!=="null"&&item.med_interactions}</h6>
                      <h6 style={{textTransform:"none"}}>Drug Interactions URL :<a href={item.med_url}> {item.med_url!=="null"&&item.med_url}</a></h6>
                      <h6>Manufacturer Name : {item.manufacturer_name}</h6>
                      <h6>created at : {item.created_at}</h6>
                      <h6>Updated at : {item.modified_at}</h6> 
                    </div>
                  </div>
                  {/* <button onClick={()=>handleEditInventory(index)} style={{alignSelf:'flex-end'}} className='btn btn-primary btn-col'>Edit Inventory</button> */}
                </>
              ) : null}
            </div>
          );
        })
      ) :  (
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "40px",
          }}
        >
          {!loading ? <h4>No Medicine to show!!</h4> : ""}
        </div>
      )
    ) : null;

    let dataDisplay1 = null;

if (category === 2) {
  if (Object.keys(featureList1).length > 0) {
    dataDisplay1 = (
      <div className="care-features">
        <div className="feature-data"  onClick={() => handleDetail(0)}>
          <h5 className="feature-title" >
            <b>
              <span style={{ cursor: "pointer" }} onClick={() => handleDetail(0)}>
                {featureList1.med_name}{" "}
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                <span style={{ fontSize: "20px", color: "green" }}>
                  (Strength: {featureList1.strength})
                </span>
              </span>
              <i
                className="fa fa-angle-down fa-fw"
                style={{ color: "#6788bc" }}
              ></i>
            </b>
          </h5>
        </div>
        {expand === 0 ? (
          <>
            <div className="separate-content" />
            <div className="row-of-features">
              <div className="col-of-features">
                {featureList1.image ? (
                  <img src={featureList1.image} alt="image" width="100" height="100" />
                ) : null}
                
                <h6>Unified Code: {featureList1.med_1}</h6>
                <h6>Composition: {featureList1.composition}</h6>
                <h6>Strength: {featureList1.strength}</h6>
                <h6>Strength Unit: {featureList1.strength_unit}</h6>
                <h6>Dosage Form: {featureList1.dosage_form}</h6>
                <h6>Route of Medicine : {featureList1.route}</h6>
                <h6>Description : {featureList1.description}</h6>
                <h6>
                  Drug Interactions :{" "}
                  {featureList1.med_interactions !== "null" &&
                    featureList1.med_interactions}
                </h6>
                <h6 style={{ textTransform: "none" }}>
                  Drug Interactions URL :<a href={featureList1.med_url}> {featureList1.med_url !== "null" && featureList1.med_url}</a>
                </h6>
                <h6>Manufacturer Name : {featureList1.manufacturer_name}</h6>
            
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  } else {
    dataDisplay1 = (
      <div
        style={{
          color: "red",
          fontSize: "25px",
          height: "200px",
          padding: "40px",
        }}
      >
        {!loading ? <h4>No Medicine to show!!</h4> : ""}
      </div>
    );
  }
}

// Remember to replace category, featureList1, expand, and handleDetail with your actual variables and functions.

  const handleBack = (e) => {
    navigate(-1);
  };
  const DeleteMedConfirmPopup =(props)=>{
    

    return (
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
  >
      <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {action==="in_stock"? <h4 id="confirm-reject">Please confirm if you want to mark this medicine as out of stock.</h4>:
        action==="out_stock"? <h4 id="confirm-reject">Please confirm if you want to mark this medicine as in stock.</h4>:
        action==="active"?<h4 id="confirm-reject">Please confirm if you want to mark this medicine as not permitted.</h4>:
        action==="inactive"?<h4 id="confirm-reject">Please confirm if you want to mark this medicine as permitted.</h4>:""}
                     
                  </Modal.Body>
        
        <Modal.Footer>
                     
  
                     <Button
                         variant="danger"
                         onClick={()=>{
      
                           setMedConfirmModalShow(false);
                             handleDeleteMed();
                             }}
                     >
                         {" "}
                         Confirm
                     </Button>
                 </Modal.Footer>
      </Modal>
    );
  }
  return (
    <>
      {/* <BackofficeNavBar/> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="container displaylist-container ">
          {/* <button style={{padding:"5%",marginTop:"5%"}}
          className="btn btn-info topleft-single-btn"
          onClick={handleBack}
        >
          {" "}
          <i className="fas fa-chevron-left back-btn-icon"></i>Back
        </button> */}
          <div className="header-display">
            <h2 className="title-of-page"> Medicine Details</h2>

            <div className="search-section">
              <div className="search-div">
                <div style={{ display: "flex",marginLeft: "10%",width:"100%" }}>
                  <input
                    className="form-control search-input"
                    type="text"
                    placeholder="Search Here"
                    value={searchTerm}
                    maxLength="30"
                    onChange={handleSearchChange}
                  />
                  <button className="btn btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </button>
                  <div className="buttons-wrapper">
                
                      {/* <button
                        style={{ marginLeft: "60%" }}
                        className="btn btn-primary btn-col"
                        onClick={() => handleMedicineList()}
                      >
                        Medicine List 
                      </button> */}
                        <button
                        style={{ marginLeft: "60%" }}
                        className="btn btn-primary btn-col"
                        onClick={() => setAddRentalShow(true)}
                      >
                        Add Medicine <i class="fas fa-plus"></i>
                      </button>
               
                  </div>
                </div>
              </div>
            </div>

            {display ? (
              <div className="suggest-display adjust-dimensions procedure1-auto1-suggest1" style={{marginLeft:"34%",marginTop:"35px",}}>
                {medicine.length > 0 ? (
                  medicine.map((item) => {
                    return (
                      <p
                        onClick={(e) => handleAddProcedure(e, item._source)}
                        className=" text-secondary"
                        style={{padding:"2%"}}
                      >

                        {search === "name_suggest__completion" ?
                          <> <div className="row"> <span className="col-6">{item._source.med_name}</span><span className="col-6">{item._source.strength}</span> </div>  </>:
                          // search === "generic_name" ?
                          // <div className="row"><span className="col-6">{item._source.med_name+","+item._source.strength}</span> <span className="col-5"> {item._source.generic_name}</span> <i style={{ float: "right" }} class={item._source.is_approved === false ? "fa fa-window-close" : "fa fa-check-square"} aria-hidden="true"></i></div> :
                            <div className="row"> <span className="col-4">{item._source.med_name}</span><span className="col-3">{item._source.strength}</span><span className="col-5">{item._source.composition}</span> </div>}
                      </p>
                    );
                  })
                ) : (
                  <h6 className="text-muted" style={{ padding: 10 }}>
                    No Medicines found !!
                  </h6>
                )}
              </div>
            ) : null}
          </div>

          <div className="test-reports-display-list">
            <div className="feature-container">{ display1 ? dataDisplay :display2 ? dataDisplay1 :dataDisplay}</div>
          </div>
         
        {display1 ?   <div className="pagn pagn-small" style={{textAlign:"center"}}>
              
              <button  onClick={handlePreviousPage} disabled={page === 1}>
              <i class="fa fa-angle-double-left"  aria-hidden="true"></i>
</button>
{visiblePages.map((pageNumber) => (
  <button
    key={pageNumber}
    onClick={() => {
      handlePageChange(pageNumber);
      setActivePage(pageNumber); // Update the active page
    }}
    className={pageNumber === activePage ? 'active-page' : ''}
  >
    {pageNumber}
  </button>
))}
<button onClick={handleNextPage} disabled={page === totalPages}>
<i class="fa fa-angle-double-right" aria-hidden="true"></i>
</button>
              {/* <Pagination
                activePage={currentPage}
                itemsCountPerPage={perPage}
                totalItemsCount={total}
                pageRangeDisplayed={totalPages}
                onChange={handlePageChange}
                
              /> */}
            </div>
         :""}

          {editShow ? (
            <EditPopUp show={editShow} onHide={() => setEditShow(false)} />
          ) : null}

          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {successShow ? (
            <SubmitPopUp
              show={successShow}
              onHide={() => {
                setSuccessShow(false);
                setRefresh(!refresh);
              }}
            />
          ) : null}

          {addRentalShow ? (
            <AddRentalPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
          {MedConfirmModalShow? 

<DeleteMedConfirmPopup
show={MedConfirmModalShow}
onHide={() =>  setMedConfirmModalShow(false)}

/>

 : 
   '' } 
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Medicine;
